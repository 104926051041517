export const TRANSLATION_EN = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Welcome!',
        slogan: 'Be Alert! Safety First!',
        buttons: {
            external: 'I am a third-party employee',
            internal: 'I am an ETT employee',
        },
    },
    BUTTONS: {
        remove: 'Delete',
        ok: 'OK',
        yes: 'Yes',
        yes_and_send: 'Yes, and send',
        no: 'No',
        cancel: 'Cancel',
        again: 'Again',
        back: 'Back',
        submit: 'Send',
        skip: 'Skip',
        next: 'Next',
    },
    DASHBOARD: {
        tile: {
            home: 'Home',
            forms: 'Forms',
            compliment: 'Award commendation',
            compliment_desc: 'I want to compliment a colleague for working safely.',
            scan_qr: 'Scan QR',
            scan_qr_desc: 'I want a QR code so that I can go to the right form.',
            instruction: 'WAVE programme',
            more: 'More',
            title: 'Hi, what are you going to do?',
            subtitle: 'Please make a choice below',
        },
        report: {
            incident: 'Report incident or MOHS',
            incident_desc: 'I want to report an incident, unsafe practice, environment and/or explosion or fire.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'History',
            news: 'News',
            cardGame: 'WAVE accelerator',
            tasks: 'Tasks',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Receive news item',
        },
        task: {
            title: 'Task assigned',
        },
        instruction: {
            title: 'Receive WAVE programme',
        },
    },
    DISCLAIMER: {
        heading: 'DISCLAIMER',
        title: 'Important:',
        content: 'Do not enter any personal data (such as names, phone numbers, licence plate numbers) unless we specifically ask for them. ' +
            'If a photo needs to be uploaded, make sure no individuals are recognisable in the image.',
        buttons: {
            agree: 'Yes, I have read the disclaimer',
        },
    },
    HELP: {
        title: 'Help Centre',
        app_version: 'Application version:',
        intro: 'The most frequently asked questions about the WAVE app are listed below. If you cannot find an answer to your question here, please contact your company’s IT helpdesk.',
        phone_number: 'Phone number',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Privacy statement',
            instruction_film: 'Instruction video',
        },
    },
    CONTACT: {
        title: 'Account details',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'E-mail address',
        phone_number: 'Phone number',
        fill_phone_number: 'Enter your phone number',
        language_selection: 'Choice of language',
        save: 'Save changes',
        logout: 'Log out',
        change_password: 'Change password',
        remove_account: 'Delete account',
        confirm_external_modal: {
            heading: 'Delete account',
            body: 'Are you sure you want to delete the account?',
            button: 'Yes, and delete account',
        },
        removing_account: 'Deleting account',
        account_removed: 'Your account has been successfully deleted.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Open form',
        unfortunately: 'Unfortunately...',
        ok_and_close: 'OK, and close page',
    },
    EXTERNAL_LOGIN: {
        title: 'External account',
        email: 'E-mail',
        password: 'Password (8 or more characters)',
        login: 'Log in',
        forgot_password: 'Forgot password',
        create_account: 'Create account',
    },
    FAQ: {
        title: 'FAQs',
        sections: [{
            title: '',
            questions: [{
                question: 'Who can ask if I have questions about the forms?',
                answer: 'For questions about the content of the forms in the app, you can contact the functional administrator (usually a QHSE colleague or safety expert) within your company.',
                open: false,
            }, {
                question: 'What do I do if I cannot answer all the questions?',
                answer: 'Of course you may not know the answer to every question. Only answer if you are sure. Otherwise, make a note that you do not know the answer.',
                open: false,
            }, {
                question: 'Who can I ask if I have technical questions about the app?',
                answer: 'For technical questions about the app, you can contact the IT helpdesk of your own company. You can find the phone number at the top of this page.',
                open: false,
            }, {
                question: 'What does the orange number under ‘Forms’ mean?',
                answer: 'A number appears on your dashboard when one or more forms have not been completed and/or sent. The number indicates how many forms there are. ' +
                    'You can find the forms which have not yet been completed and/or sent at the bottom of the forms page. By clicking on them, you can complete and send the forms. It is also possible to delete forms which have not yet been completed and/or sent.',
                open: false,
            }, {
                question: 'Can I make changes to a report I have sent?',
                answer: 'No, unfortunately this is not possible. However, you can always contact the functional administrator (usually a QHSE colleague or safety expert) within your company to pass on any changes.',
                open: false,
            }, {
                question: 'What happens to my report after I submit it?',
                answer: 'The reports are automatically sent to the person responsible for the project. Who that is can vary depending on the form. ' +
                    'The functional administrator (usually a QHSE colleague or safety expert) receives a copy of the form. In addition, you will also receive a copy of the form by e-mail.',
                open: false,
            }, {
                question: 'Can I delete a report?',
                answer: 'Yes, you can. However, this is not possible via the app. Contact the functional administrator (usually a QHSE colleague or safety expert) within your company to delete the report.',
                open: false,
            }, {
                question: 'Can I work offline with the app?',
                answer: 'Yes, you can. You can always complete a form and save it in the app. If the form is not complete, you will get a message that it cannot be sent. The form will be saved as a draft. ' +
                    'The draft will not be automatically sent as soon as you are connected to the internet. You need to open the draft and then send it. ' +
                    'Unfortunately, external users cannot complete all forms if they are working offline. They can only report unsafe situations offline. ' +
                    'For the other forms they need to be working online.',
                open: false,
            }, {
                question: 'Can a subcontractor use the app?',
                answer: 'Yes, they can. A subcontractor can log in as an external user.',
                open: false,
            }, {
                question: 'Can I change my phone number?',
                answer: 'Yes, you can. All ETT employees can change their phone numbers. You can do this in your profile in the app.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Report',
        warning: 'Warning',
        close: 'Close',
        open: 'Open',
        error_general: 'An unexpected error has occurred.',
        error_retrieving_profile: 'Something went wrong when retrieving your profile',
        something_wrong_saving: 'Something went wrong when submitting the form',
        something_wrong_task: 'Something went wrong when sending the task Complete the comments or try again later.',
        something_wrong_logout: 'Something went wrong when logging out',
        offline: 'You are currently working offline. As a result, this function is currently not available',
        account_removed_failed: 'Something went wrong when deleting your account. Try again later.',
        inactive_form: 'This form is no longer valid. Complete the form again.',
        no_access: 'You do not have access to WAVE. Contact your administrator,',
        error_message: 'Error message:',
        login_failed: 'Login failed',
        error_logged_out: 'Unfortunately, something went wrong when retrieving the data. You are being logged out',
        retrieving_options: 'Retrieve options/contacts',
        aggregating: 'Aggregate data',
        update_failed: 'Editing failed',
        retrieval_failed: 'Retrieval failed',
        error_forms_no_network: 'Unable to edit forms (no network connection). Try to edit the forms again?',
        error_forms_slow_network: 'Unable to edit forms (slow network connection). Try to edit the forms again?',
        error_forms_retrieval_no_network: 'Unable to retrieve forms (no network connection). Check the network connection and try again.',
        error_forms_retrieval_slow_network: 'Unable to retrieve forms (slow network connection). Check the network connection and try again.',
        error_popup_blocked: 'Allow pop-ups in order to log in.',
        image_file_needed: 'File is not a valid image',
        route_not_found: 'Redirecting failed',
        route_not_found_explanation: 'Redirecting to the page or form failed. The cause can be that it is inaccessible to you, the page or form no longer exists or the form isn\'t available in your language.',
    },
    FORGOT_PASSWORD: {
        title: 'Forgot password',
        subtitle: 'If your e-mail address is known, you will receive an e-mail containing instructions to reset the password.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'Enter e-mail address',
        email: 'E-mail',
        next: 'Next',
    },
    NEWS: {
        title: 'News',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    SEARCH_PLACEHOLDER: 'Search',
    PASSWORD_CHANGE: {
        title: 'Change password',
        current_password: 'Current password',
        password_explanation: 'The password must be at least 8 characters long and contain an uppercase letter, a number and a special character',
        new_password: 'New password',
        repeat_new_password: 'Repeat new password',
        passwords_dont_match: 'Passwords do not match',
        password_changed: 'Your password has been changed You need to log in again',
        current_password_incorrect: 'The password entered does not match your current password',
        confirm: 'Confirm',
    },
    QUESTIONNAIRE: {
        part: 'Component',
        map_offline: 'You are working offline. The card is only available in online mode.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'History',
        subtitle: 'View your sent forms here',
        no_forms: 'No reports have yet been sent',
        submitted_at: 'Submitted on',
        processed_at: 'Handled on',
        processed_submitted_at: 'Handled, submitted on',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Component',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Thank you',
        subtitle: 'The form is being processed. A copy of the form will be sent to you by e-mail. You can also find the completed form under History in this app.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Created on:',
        completed_on: 'Completed on:',
        place_remark: 'Put your comment(s) here',
        remark: 'Comment(s):',
        task_denied: 'Not handled',
        task_completed: 'Handled',
        are_you_sure: 'Are you sure you want to set up "{{statusLabel}}" this task?',
        page_leave: 'Leave page',
        page_leave_explanation: 'You completed the “comments” field for the task. If you leave the page, this change will be lost. Are you sure you want to leave the page?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Tasks in progress',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Planned forms',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Completed tasks',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Completed on:',
        deadline_passed: 'Deadline passed:',
        deadline_at: 'Deadline passed on:',
        created_at: 'Created on:',
    },
    CARD_GAME: {
        question: {
            label: 'Question',
        },
        toolbar: {
            button_label: 'New card',
            close: 'Close',
        },
    },
    ERROR: {
        form: {
            warning: 'Warning',
            not_found: 'No forms are available in your language yet.',
            directIncidentOnly: 'The form for “Report incident or MOHS” is currently not available. Please contact your system administrator.',
            directComplimentOnly: 'The form is not available in your language.',
            questionnaireIdToSelect: 'This task is currently not available for the selected organisational unit. Please select another organisational unit or contact your system administrator.',
        },
        no_access: {
            title: 'No rights',
            explanation: 'You do not have access to these forms. Go back to the home screen.',
        },
    },
    REGISTRATION: {
        title: 'Create new account',
        email: 'E-mail',
        first_name: 'First name',
        sur_name: 'Surname',
        mobile_phone: 'Mobile number',
        password: 'Password',
        password_explanation: 'The password must be at least 8 characters long and contain an uppercase letter, a number and a special character',
        repeat_password: 'Repeat password',
        passwords_dont_match: 'Passwords do not match',
        approve: 'I agree to',
        privacy: 'Privacy policy',
        create_account: 'Create account',
        account_exists: 'There is already an account associated with this e-mail address',
        error_something_wrong: 'Something went wrong with the registration.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Confirm your e-mail address',
        subtitle: 'Thank you for creating an account. Within a few moments, you will receive an e-mail asking you to confirm your e-mail address',
    },
    REGISTRATION_SMS: {
        title: 'Phone number verification',
        subtitle: 'Enter the verification code you received by text message here. This code will be sent to the specified phone number.',
        verification_code: 'Verification code',
        verify: 'Check verification code',
        incorrect_code: 'Invalid verification code',
        error_something_wrong: 'Something went wrong with the verification.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Forms not yet completed',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Planned forms',
        available_forms: 'Available forms',
        workplace_forms: 'Workplace inspection',
        retrieve_forms: 'Retrieve forms',
        retrieve_form: 'Retrieve form',
        choose_action: 'Select your action',
        open: 'Open',
        upload: 'Put online',
        download_and_open: 'Download and open form',
        remove: 'Delete',
        something_went_wrong: 'Something went wrong when retrieving the forms',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Select your project',
        choose_business_unit: 'Select your business unit',
        no_organisations: 'No organisations available.',
        search_on: 'Search by',
        choose_your: 'Select your',
        organisation: 'company',
        project: 'project',
        projects: 'Projects',
        business_unit: 'business unit',
        business_units: 'Business units',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Do you want to send the form?',
        description: 'By sending it you can no longer change anything within this sent form.',
        account_blocked: 'This form cannot be sent until your account has been approved',
        offline: 'You are working offline, which means you cannot send the form. Send the form as soon as you are back online',
        something_went_wrong: 'Something went wrong when submitting the form',
        confirm: 'Yes, report incident',
        cancel: 'No, back',
    },
    SCAN_QR: {
        incorrect_result: 'Incorrect QR code. Scan a correct WAVE QR code.',
        no_result: 'No QR code scanned.',
        no_permission: 'The app cannot access the camera. Give the app permission before scanning a QR code.',
        try_again: 'Scan again',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Add',
            place_remark: 'Put your comment(s) here',
            max_attachments_reached: 'The maximum number of attachments has been reached.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Multiple options possible',
            no_choices_selected: 'No option(s) have been selected.',
        },
        checkbox_searchable: {
            selected_choices: 'Selected option(s)',
            no_choices_found: 'No option(s) have been found.',
            visible_choices: '{{maxListChoices}} of the {{filteredChoices}}options are visible.',
            limit_choices: 'Limit the options list by searching.',
        },
        lat_long: {
            current_location: 'Use current location',
            or: 'Or',
            city: 'Town/city',
            address: 'Address',
            invalid_address: 'This is not a valid address.',
        },
        manual_choice: {
            new_choice: 'Enter new option',
            choice_exists: 'This option already exists.',
        },
        photo: {
            subtitle: 'Add photos by clicking on the plus sign',
            gallery: 'Photo gallery',
            drop_explanation: 'Click, drag or paste photos here to add them',
            max_reached: 'The maximum number of photos that can be attached has been reached.',
            take_a_photo: 'Take a photo',
            upload_from_gallery: 'Upload from gallery',
        },
        questionnaire_footer: {
            chapters: 'Chapters',
            previous: 'Previous',
        },
        questionnaire_overlay: {
            help_text: 'After answering the question, click on “Next” to go to the next question.',
        },
        radio: {
            choose: 'Make a selection',
            no_choice_selected: 'No option has been selected.',
        },
        radio_searchable: {
            selected_choice: 'Selected option',
            no_choice_found: 'No options have been found.',
            visible_choices: '{{maxListChoices}} of the {{filteredChoices}}options are visible.',
            limit_choices: 'Limit the options list by searching.',
        },
        searchable_choices: {
            search: 'Search',
        },
        select_concept: {
            last_change: 'Last edited:',
        },
        select_item: {
            submit_before: 'Submit before ',
        },
    },
};
