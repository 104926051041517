export const TRANSLATION_NL = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm [uur]',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Welkom',
        slogan: 'Wees Alert! Veiligheid Eerst!',
        buttons: {
            external: 'Ik ben een externe',
            internal: 'Ik ben een ETT-medewerker',
        },
    },
    BUTTONS: {
        remove: 'Verwijderen',
        ok: 'OK',
        yes: 'Ja',
        yes_and_send: 'Ja, en versturen',
        no: 'Nee',
        cancel: 'Annuleren',
        again: 'Opnieuw',
        back: 'Terug',
        submit: 'Versturen',
        skip: 'Overslaan',
        next: 'Volgende',
    },
    DASHBOARD: {
        tile: {
            home: 'Home',
            forms: 'Formulieren',
            compliment: 'Pluim uitdelen',
            compliment_desc: 'Ik wil een collega een pluim uitdelen vanwege veilig werken.',
            scan_qr: 'Scan QR',
            scan_qr_desc: 'Ik wil een QR-code zodat ik bij het juiste formulier terecht kom.',
            instruction: 'WAVE-programma',
            more: 'Meer',
            title: 'Hoi, wat wil je doen?',
            subtitle: 'Maak hieronder een keuze',
        },
        report: {
            incident: 'Incident of MOHS melden',
            incident_desc: 'Ik wil een incident, onveilige handeling, milieu en/of explosie of brand melden.',
        },
        tasks: {
            notification: {
                singular: 'Je hebt <b>{{value}} openstaande</b> taak',
                plural: 'Je hebt <b>{{value}} openstaande</b> taken',
            },
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Historie',
            news: 'Nieuws',
            cardGame: 'WAVE-versneller',
            tasks: 'Taken',
        },
        tasks: {
            notification: {
                singular: '{{value}} openstaande taak',
                plural: '{{value}} openstaande taken',
            },
        },
    },
    HEADER: {
        structure: 'Nieuw formulier',
    },
    PUSH: {
        news: {
            title: 'Nieuwsbericht ontvangen',
        },
        task: {
            title: 'Taak toegewezen',
        },
        instruction: {
            title: 'WAVE-programma ontvangen',
        },
    },
    DISCLAIMER: {
        heading: 'DISCLAIMER',
        title: 'Let op:',
        content: 'Vul in de app geen persoonsgegevens (zoals namen, telefoonnummers, kentekens) in, tenzij hier specifiek om wordt gevraagd. ' +
            'Als er een foto moet worden ge-upload, zorg er dan voor dat er geen personen herkenbaar in beeld zijn.',
        buttons: {
            agree: 'Ja, ik heb de disclaimer gelezen',
        },
    },
    HELP: {
        title: 'Helpcentrum',
        app_version: 'Applicatie versie:',
        intro: 'Hieronder staan de meest gestelde vragen over de WAVE-app. Kun je hier geen antwoord vinden op je vraag, neem dan contact op met de IT-helpdesk van jouw onderneming.',
        phone_number: 'Telefoonnummer',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Privacyverklaring',
            instruction_film: 'Instructiefilm',
        },
    },
    CONTACT: {
        title: 'Accountgegevens',
        subtitle: 'Zie jouw accountgegevens in, log uit of pas de taal van de app aan.',
        email_address: 'E-mailadres',
        phone_number: 'Telefoonnummer',
        fill_phone_number: 'Vul je telefoonnummer in',
        language_selection: 'Taalkeuze',
        save: 'Wijzigingen opslaan',
        logout: 'Uitloggen',
        change_password: 'Wachtwoord wijzigen',
        remove_account: 'Verwijder account',
        confirm_external_modal: {
            heading: 'Account verwijderen',
            body: 'Weet je het zeker dat je je account wilt verwijderen?',
            button: 'Ja, en account verwijderen',
        },
        removing_account: 'Account aan het verwijderen',
        account_removed: 'Het verwijderen van je account is gelukt.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Openen formulier',
        unfortunately: 'Helaas...',
        ok_and_close: 'Ok, en sluit pagina',
    },
    EXTERNAL_LOGIN: {
        title: 'Extern account',
        email: 'E-mail',
        password: 'Wachtwoord (8 of meer tekens)',
        login: 'Inloggen',
        forgot_password: 'Wachtwoord vergeten',
        create_account: 'Account aanmaken',
    },
    FAQ: {
        title: 'Veelgestelde vragen',
        sections: [{
            title: '',
            questions: [{
                question: 'Bij wie kan ik terecht voor vragen over de formulieren?',
                answer: 'Voor vragen over de inhoud van de formulieren in de app kun je contact opnemen met de functioneel beheerder (meestal KAM-collega of Veiligheidskundige) binnen jouw eigen onderneming.',
                open: false,
            }, {
                question: 'Wat moet ik doen als ik geen antwoord kan geven op alle gestelde vragen?',
                answer: 'Het kan natuurlijk voorkomen dat je niet overal het antwoord op hebt. Geef alleen antwoord als je het zeker weet. In andere gevallen geef je aan dat je het niet weet.',
                open: false,
            }, {
                question: 'Bij wie kan ik terecht voor technische vragen over de app?',
                answer: 'Voor technische vragen over de app kun je contact opnemen met de IT-helpdesk van je eigen onderneming. Het telefoonnummer vind je bovenaan deze pagina.',
                open: false,
            }, {
                question: 'Wat betekent het oranje getal bij \'Formulieren\'?',
                answer: 'Er verschijnt een getal op jouw dashboard wanneer formulieren nog niet afgerond en/of verzonden zijn. Het getal geeft aan om hoeveel formulieren het gaat. ' +
                    'Onderaan de formulierenpagina vind je de openstaande formulieren. Door deze aan te klikken kan je de formulieren alsnog afronden en verzenden. Het is ook mogelijk om openstaande formulieren te verwijderen.',
                open: false,
            }, {
                question: 'Kan ik nog een wijziging aanbrengen in mijn verzonden melding?',
                answer: 'Nee, dit is helaas niet mogelijk. Wel kun je altijd contact opnemen met de functioneel beheerder (meestal KAM-collega of Veiligheidskundige) van jouw eigen onderneming om de wijzigingen door te geven.',
                open: false,
            }, {
                question: 'Wat gebeurt er met mijn verzonden melding?',
                answer: 'De meldingen worden automatisch verzonden naar de projectverantwoordelijke van het project. Dit kan per formulier verschillen. ' +
                    'De functioneel beheerder (meestal KAM-collega of Veiligheidskundige) ontvangt een kopie van het formulier. Daarnaast ontvang je zelf ook een kopie van de melding via mail.',
                open: false,
            }, {
                question: 'Kan ik een melding verwijderen?',
                answer: 'Ja, dat kan. Dit is alleen niet mogelijk via de app. Neem contact op met de functioneel beheerder (meestal KAM-collega of Veiligheidskundige) binnen jouw organisatie om de melding te verwijderen.',
                open: false,
            }, {
                question: 'Kan ik offline werken met de app?',
                answer: 'Ja, dat kan. Je kunt altijd een formulier invullen en opslaan in de app. Bij het afronden van het formulier verschijnt de melding dat deze niet verzonden kan worden. Het formulier wordt als concept opgeslagen. ' +
                    'Zodra je verbonden bent met internet wordt het concept niet automatisch verzonden. Je moet het concept openen en dan verzenden. ' +
                    'Helaas geldt voor externe gebruikers dat niet alle formulieren ingevuld kunnen worden als ze offline werken. Alleen het melden van een onveilige situatie kan offline. ' +
                    'Voor de overige formulieren moeten zij online werken.',
                open: false,
            }, {
                question: 'Kan een onderaannemer gebruik maken van de app?',
                answer: 'Ja, dat kan. Een onderaannemer kan inloggen als externe gebruiker.',
                open: false,
            }, {
                question: 'Kan ik mijn telefoonnummer aanpassen?',
                answer: 'Ja, dat kan. Alle ETT-medewerkers kunnen hun telefoonnummer wijzigen. Dit kan bij je profiel in de app.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Melding',
        warning: 'Waarschuwing',
        close: 'Sluiten',
        open: 'Openen en lezen',
        error_general: 'Er is een onverwachte fout opgetreden.',
        error_retrieving_profile: 'Er is iets fout gegaan bij het ophalen van je profiel',
        something_wrong_saving: 'Er is iets fout gegaan bij het versturen van het formulier',
        something_wrong_task: 'Er is iets fout gegaan bij het versturen van de taak. Vul de opmerkingen in of probeer het later nog eens.',
        something_wrong_logout: 'Er is iets fout gegaan bij het uitloggen',
        offline: 'Je werkt nu offline. Hierdoor is deze functie nu niet beschikbaar',
        account_removed_failed: 'Er is iets fout gegaan tijdens het verwijderen van je account. Probeer het later nog eens.',
        inactive_form: 'Dit is geen geldig formulier meer. Vul het formulier opnieuw in.',
        no_access: 'Je hebt geen toegang tot WAVE. Neem contact op met je beheerder,',
        error_message: 'Foutmelding:',
        login_failed: 'Het inloggen is mislukt',
        error_logged_out: 'Er ging helaas iets mis met het ophalen van de data. Je wordt uitgelogd',
        retrieving_options: 'Ophalen opties / contacten',
        aggregating: 'Aggregeren gegevens',
        update_failed: 'Bijwerken mislukt',
        retrieval_failed: 'Ophalen mislukt',
        error_forms_no_network: 'Formulieren konden niet bijgewerkt worden (geen netwerkverbinding). Nogmaals proberen de formulieren bij te werken?',
        error_forms_slow_network: 'Formulieren konden niet bijgewerkt worden (trage netwerkverbinding). Nogmaals proberen de formulieren bij te werken?',
        error_forms_retrieval_no_network: 'Formulieren konden niet opgehaald worden (geen netwerkverbinding). Controleer de netwerkverbinding en probeer opnieuw.',
        error_forms_retrieval_slow_network: 'Formulieren konden niet opgehaald worden (trage netwerkverbinding). Controleer de netwerkverbinding en probeer opnieuw.',
        error_popup_blocked: 'Je moet pop-ups toestaan om in te kunnen loggen.',
        image_file_needed: 'Geen geldige afbeelding toegevoegd',
        route_not_found: 'Doorverwijzing is mislukt',
        route_not_found_explanation: 'De doorverwijzing naar de pagina of formulier is niet gelukt. De oorzaak kan zijn dat u geen toegang heeft, dat het formulier niet langer bestaat of niet beschikbaar is voor uw taal.',
    },
    FORGOT_PASSWORD: {
        title: 'Wachtwoord vergeten',
        subtitle: 'Als je e-mailadres bekend is, dan ontvang je een e-mail met instructies om het wachtwoord te resetten.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'E-mailadres invoeren',
        email: 'E-mail',
        next: 'Volgende',
    },
    NEWS: {
        title: 'Nieuws',
        subtitle: 'WAVE nieuws berichten',
        search: 'Zoek op nieuws',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Kies een thema',
            subject: 'Kies een onderwerp',
        },
        search: {
            theme: 'Zoek op thema',
            subject: 'Zoek op onderwerp',
        },
    },
    SEARCH_PLACEHOLDER: 'Zoeken',
    PASSWORD_CHANGE: {
        title: 'Wachtwoord wijzigen',
        current_password: 'Huidig wachtwoord',
        password_explanation: 'Het wachtwoord moet minimaal 8 tekens lang zijn, een hoofdletter, cijfer en een speciaal teken bevatten',
        new_password: 'Nieuw wachtwoord',
        repeat_new_password: 'Herhaal nieuw wachtwoord',
        passwords_dont_match: 'De wachtwoorden komen niet overeen',
        password_changed: 'Je wachtwoord is aangepast. Je moet opnieuw inloggen',
        current_password_incorrect: 'Het ingevulde wachtwoord komt niet overeen met je huidige wachtwoord',
        confirm: 'Bevestigen',
    },
    QUESTIONNAIRE: {
        part: 'Onderdeel',
        map_offline: 'Je werkt offline. De kaart is alleen in online modus beschikbaar.',
        from: 'van',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Historie',
        subtitle: 'Bekijk hier jouw verstuurde formulieren',
        no_forms: 'Er zijn nog geen ingezonden meldingen',
        submitted_at: 'Ingediend op',
        processed_at: 'Afgehandeld op',
        processed_submitted_at: 'Afgehandeld, ingediend op',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Onderdeel',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Bedankt',
        subtitle: 'Het formulier wordt in behandeling genomen. Je krijgt een kopie van het formulier via e-mail toegezonden. Het ingevulde formulier is ook terug te vinden onder Historie in deze app.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Aangemaakt op:',
        completed_on: 'Afgerond op:',
        place_remark: 'Plaats hier uw opmerking(en)',
        remark: 'Opmerking(en):',
        task_denied: 'Niet afgehandeld',
        task_completed: 'Afgehandeld',
        are_you_sure:  'Weet je zeker dat je deze taak op "{{statusLabel}}" wilt zetten?',
        page_leave: 'Pagina verlaten',
        page_leave_explanation: 'Je hebt bij de taak het invoerveld "opmerkingen" ingevuld. Als je de pagina verlaat gaat deze aanpassing verloren. Weet je het zeker dat je de pagina wilt verlaten?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Openstaande taken',
        subtitle: 'Klik op een taak om deze uit te voeren',
        planned_forms: 'Geplande formulieren',
        planned_forms_subtitle: 'Hieronder zijn formulieren te zien die voor een bepaalde datum verstuurd moeten zijn',
        closed_tasks: 'Afgesloten taken',
        closed_tasks_subtitle: 'Hieronder zijn taken te zien welke afgerond zijn',
        closed_on: 'Afgerond op:',
        deadline_passed: 'Deadline verstreken op:',
        deadline_at: 'Deadline verstrijkt op:',
        created_at: 'Aangemaakt op:',
    },
    CARD_GAME: {
        question: {
            label: 'Vraagstelling',
        },
        toolbar: {
            button_label: 'Nieuwe kaart',
            close: 'Sluiten',
        },
    },
    ERROR: {
        form: {
            warning: 'Waarschuwing',
            not_found: 'Er zijn nog geen formulieren beschikbaar',
            directIncidentOnly: 'Het formulier voor "Incident of MOHS melden" is op dit moment niet beschikbaar. Neem contact op met je beheerder.',
            directComplimentOnly: 'Het formulier voor "Deel een pluim uit" is op dit moment niet beschikbaar. Neem contact op met je beheerder.',
            questionnaireIdToSelect: 'Deze taak is op dit moment niet beschikbaar voor de geselecteerde organisatie-eenheid. Selecteer een andere organisatie-eenheid of neem contact op met je beheerder.',
        },
        no_access: {
            title: 'Geen toegang',
            explanation: 'Je hebt geen toegang tot deze formulieren. Ga terug naar het dashboard.',
        },
    },
    REGISTRATION: {
        title: 'Nieuw account aanmaken',
        email: 'E-mail',
        first_name: 'Voornaam',
        sur_name: 'Achternaam',
        mobile_phone: 'Mobiel nummer',
        password: 'Wachtwoord',
        password_explanation: 'Het wachtwoord moet minimaal 8 tekens lang zijn, een hoofdletter, cijfer en een speciaal teken bevatten',
        repeat_password: 'Herhaal wachtwoord',
        passwords_dont_match: 'De wachtwoorden komen niet overeen',
        approve: 'Ik ga akkoord met het',
        privacy: 'Privacybeleid',
        create_account: 'Account aanmaken',
        account_exists: 'Er bestaat al een account met het ingevulde e-mailadres',
        error_something_wrong: 'Er is iets fout gegaan bij het registreren.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Bevestig je e-mailadres',
        subtitle: 'Bedankt voor het aanmaken van een account. Je ontvangt binnen enkele ogenblikken een e-mailbericht om jouw e-mailadres te bevestigen',
    },
    REGISTRATION_SMS: {
        title: 'Verificatie telefoonnummer',
        subtitle: 'Vul hier de verificatie code in die je hebt ontvangen per SMS. Deze code wordt gestuurd naar het opgegeven telefoonnummer.',
        verification_code: 'Verificatie code',
        verify: 'Controleer verificatie code',
        incorrect_code: 'Ongeldige verificatiecode',
        error_something_wrong: 'Er is iets fout gegaan bij het verifiëren.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Openstaande formulieren',
        open_forms_subtitle: 'Klik op een formulier om verder te gaan',
        new_form: 'Nieuw formulier',
        new_form_subtitle: 'Klik op een formulier om een nieuw formulier te starten',
        planned_forms: 'Geplande formulieren',
        available_forms: 'Beschikbare formulieren',
        workplace_forms: 'Werkplekinspectie',
        retrieve_forms: 'Ophalen formulieren',
        retrieve_form: 'Ophalen formulier',
        choose_action: 'Kies je actie',
        open: 'Openen',
        upload: 'Zet online',
        download_and_open: 'Download en open formulier',
        remove: 'Verwijderen',
        something_went_wrong: 'Er is iets fout gegaan tijdens het ophalen van de formulieren',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Kies je project',
        choose_business_unit: 'Kies je businessunit',
        no_organisations: 'Geen organisaties beschikbaar.',
        search_on: 'Zoek op',
        choose_your: 'Kies jouw',
        organisation: 'onderneming',
        project: 'project',
        projects: 'Projecten',
        business_unit: 'business unit',
        business_units: 'Business units',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Wil je het formulier versturen?',
        description: 'Door het te versturen kun je niks meer aanpassen binnen dit verstuurde formulier.',
        account_blocked: 'Dit formulier kan nog niet verzonden worden totdat je account is goedgekeurd',
        offline: 'Je werkt offline en kunt het formulier daardoor niet versturen. Verstuur het formulier zodra je weer online bent',
        something_went_wrong: 'Er is iets fout gegaan bij het versturen van het formulier',
        confirm: 'Ja, versturen',
        cancel: 'Nee, terug',
    },
    SCAN_QR: {
        incorrect_result: 'Incorrecte QR code. Scan een correcte WAVE QR code.',
        no_result: 'Geen QR code gescand.',
        no_permission: 'De app heeft geen toegang tot de camera. Geef de app toestemming alvorens een QR code te scannen.',
        try_again: 'Scan opnieuw',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Toevoegen',
            place_remark: 'Plaats hier uw opmerking(en)',
            max_attachments_reached: 'Het maximale aantal bijlages dat toegevoegd kan worden is bereikt.',
            note: 'Opmerking',
            picture: 'Afbeelding',
        },
        checkbox: {
            multiple_choices_possible: 'Meerdere keuzes mogelijk',
            no_choices_selected: 'Er zijn geen keuze(s) geselecteerd.',
        },
        checkbox_searchable: {
            selected_choices: 'Geselecteerde keuze(s)',
            no_choices_found: 'Er zijn geen keuze(s) gevonden.',
            visible_choices: '{{maxListChoices}} van de {{filteredChoices}} keuzes zijn zichtbaar.',
            limit_choices: 'Beperk de keuzelijst door te zoeken.',
        },
        lat_long: {
            current_location: 'Gebruik huidige locatie',
            or: 'Of',
            city: 'Plaats',
            address: 'Adres',
            invalid_address: 'Dit is geen geldig adres.',
        },
        manual_choice: {
            new_choice: 'Nieuwe keuze invoeren',
            choice_exists: 'Deze keuzemogelijkheid bestaat al.',
        },
        photo: {
            subtitle: 'Voeg foto\'s toe door op het plusje te klikken',
            gallery: 'Fotogalerij',
            drop_explanation: 'Klik, sleep of plak hier foto\'s om ze toe te voegen',
            max_reached: 'Het maximale aantal foto\'s dat toegevoegd kan worden is bereikt.',
            take_a_photo: 'Maak een foto',
            upload_from_gallery: 'Uploaden vanuit galerij',
        },
        questionnaire_footer: {
            chapters: 'Hoofdstukken',
            previous: 'Vorige',
        },
        questionnaire_overlay: {
            help_text: 'Klik na het beantwoorden van de vraag op “Volgende” om naar de volgende vraag te gaan.',
        },
        radio: {
            choose: 'Maak een keuze',
            no_choice_selected: 'Er is geen keuze geselecteerd.',
        },
        radio_searchable: {
            selected_choice: 'Geselecteerde keuze',
            no_choice_found: 'Er zijn geen keuzes gevonden.',
            visible_choices: '{{maxListChoices}} van de {{filteredChoices}} keuzes zijn zichtbaar.',
            limit_choices: 'Beperk de keuzelijst door te zoeken.',
        },
        searchable_choices: {
            search: 'Zoeken',
        },
        select_concept: {
            last_change: 'Laatste bewerking:',
        },
        select_item: {
            submit_before: 'Inzenden voor ',
        },
    },
};
