export const TRANSLATION_PT = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Bem-vindo!',
        slogan: 'Estejam alerta! Segurança em primeiro lugar!',
        buttons: {
            external: 'Sou um funcionário terceirizado',
            internal: 'Sou um funcionário da ETT',
        },
    },
    BUTTONS: {
        remove: 'Eliminar',
        ok: 'OK',
        yes: 'Sim',
        yes_and_send: 'Sim, e enviar',
        no: 'Não',
        cancel: 'Cancelar',
        again: 'Novamente',
        back: 'Voltar',
        submit: 'Enviar',
        skip: 'Ignorar',
        next: 'Próximo',
    },
    DASHBOARD: {
        tile: {
            home: 'Início',
            forms: 'Formulários',
            compliment: 'Dar elogio',
            compliment_desc: 'Quero elogiar um colega por trabalhar com segurança.',
            scan_qr: 'Escanear QR',
            scan_qr_desc: 'Quero um código QR para poder acessar o formulário correto.',
            instruction: 'Programa WAVE',
            more: 'Mais',
            title: 'Olá, o que você vai fazer?',
            subtitle: 'Faça uma escolha abaixo',
        },
        report: {
            incident: 'Denunciar incidente ou MOHS',
            incident_desc: 'Quero relatar um incidente, prática insegura, ambiente e/ou explosão ou incêndio.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Histórico',
            news: 'Notícias',
            cardGame: 'WAVE accelerator',
            tasks: 'Tarefas',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Receber notícia',
        },
        task: {
            title: 'Tarefa atribuída',
        },
        instruction: {
            title: 'Receber programa WAVE',
        },
    },
    DISCLAIMER: {
        heading: 'DECLARAÇÃO DE EXONERAÇÃO DE RESPONSABILIDADE',
        title: 'Importante:',
        content: 'Não introduza dados pessoais (tais como nomes, números de telefone, números de matrícula) exceto mediante solicitação expressa da nossa parte. ' +
            'Se for necessário submeter uma fotografia, certifique-se de que as pessoas na imagem não são identificáveis.',
        buttons: {
            agree: 'Sim, li a declaração de exoneração de responsabilidade',
        },
    },
    HELP: {
        title: 'Centro de apoio',
        app_version: 'Versão da aplicação:',
        intro: 'Seguem abaixo as perguntas mais frequentemente colocadas sobre a aplicação WAVE. Se não encontrar aqui uma resposta à sua questão, contacte o centro de apoio informático da sua empresa.',
        phone_number: 'Número de telefone',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Declaração de privacidade',
            instruction_film: 'Vídeo de instrução',
        },
    },
    CONTACT: {
        title: 'Dados de contacto',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'Endereço de e-mail',
        phone_number: 'Número de telefone',
        fill_phone_number: 'Introduza o seu número de telefone',
        language_selection: 'Seleção de idioma',
        save: 'Guardar alterações',
        logout: 'Terminar sessão',
        change_password: 'Alterar palavra-passe',
        remove_account: 'Eliminar conta',
        confirm_external_modal: {
            heading: 'Eliminar conta',
            body: 'Tem a certeza de que pretende eliminar a conta?',
            button: 'Sim, e eliminar conta',
        },
        removing_account: 'A eliminar conta',
        account_removed: 'A sua conta foi eliminada com êxito.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Abrir formulário',
        unfortunately: 'Infelizmente…',
        ok_and_close: 'OK, e fechar página',
    },
    EXTERNAL_LOGIN: {
        title: 'Conta externa',
        email: 'E-mail',
        password: 'Palavra-passe (8 ou mais carateres)',
        login: 'Iniciar sessão',
        forgot_password: 'Esqueci-me da palavra-passe',
        create_account: 'Criar conta',
    },
    FAQ: {
        title: 'Perguntas frequentes',
        sections: [{
            title: '',
            questions: [{
                question: 'Quem pode perguntar se tenho dúvidas em relação aos formulários?',
                answer: 'Em caso de dúvidas sobre o conteúdo dos formulários na aplicação, pode contactar o administrador da função (geralmente um colaborador da área de Qualidade, Saúde, Segurança e Ambiente ou um especialista em segurança) da sua empresa.',
                open: false,
            }, {
                question: 'O que devo fazer se não puder responder a todas as questões?',
                answer: 'Naturalmente, pode não saber responder a todas as questões. Responda apenas se tiver a certeza. Caso contrário, tome nota indicando não saber a resposta.',
                open: false,
            }, {
                question: 'A quem devo colocar dúvidas técnicas que posso vir a ter em relação à aplicação?',
                answer: 'Em caso de dúvidas técnicas em relação à aplicação, pode contactar o centro de apoio informático da sua própria empresa. O número de telefone encontra-se na parte superior desta página.',
                open: false,
            }, {
                question: 'Qual é o significado do número laranja abaixo de ‘Formulários’?',
                answer: 'Quando um ou mais formulários estiverem por preencher e/ou enviar, irá surgir um número no seu painel de informações (dashboard). O número indica quantos formulários existem. ' +
                    'Os formulários que estão por preencher e/ou enviar encontram-se na parte inferior da página de formulários. Basta clicar nos formulários para preencher e enviar. É também possível eliminar formulários que estão por preencher e/ou enviar.',
                open: false,
            }, {
                question: 'Posso efetuar alterações a uma denúncia que enviei?',
                answer: 'Não, infelizmente isso não é possível. No entanto, pode sempre contactar o administrador da função (geralmente um colaborador da área de Qualidade, Saúde, Segurança e Ambiente ou um especialista em segurança) da sua empresa para comunicar quaisquer alterações.',
                open: false,
            }, {
                question: 'O que acontece à minha denúncia depois de a submeter?',
                answer: 'As denúncias são enviadas automaticamente ao responsável pelo projeto. O responsável em questão pode diferir de acordo com o formulário. ' +
                    'O administrador da função (geralmente um colaborador da área de Qualidade, Saúde, Segurança e Ambiente ou um especialista em segurança) recebe uma cópia do formulário. Além disso, será também enviada para si uma cópia do formulário por e-mail.',
                open: false,
            }, {
                question: 'Posso eliminar uma denúncia?',
                answer: 'Sim, pode. No entanto, isso não é possível através da aplicação. Contacte o administrador da função (geralmente um colaborador da área de Qualidade, Saúde, Segurança e Ambiente ou um especialista em segurança) da sua empresa para eliminar a denúncia.',
                open: false,
            }, {
                question: 'Posso utilizar a aplicação se estiver offline?',
                answer: 'Sim, pode. Pode preencher um formulário e guardá-lo na aplicação a qualquer momento. Se o formulário não estiver preenchido, irá receber uma mensagem a indicar que o mesmo não pode ser enviado. O formulário será guardado como rascunho. ' +
                    'O rascunho não será enviado automaticamente assim que estiver ligado à Internet. É necessário abrir o rascunho e depois enviá-lo. ' +
                    'Infelizmente, os utilizadores externos não podem preencher todos os formulários se estiverem offline. Se estiverem offline, podem apenas denunciar situações inseguras. ' +
                    'Para aceder aos outros formulários, devem estar online.',
                open: false,
            }, {
                question: 'A aplicação pode ser utilizada por terceirizados?',
                answer: 'Sim, pode. Um terceirizado pode iniciar sessão como utilizador externo.',
                open: false,
            }, {
                question: 'Posso alterar o meu número de telefone?',
                answer: 'Sim, pode. Todos os funcionários ETT podem alterar os seus números de telefone. Esta opção encontra-se disponível no seu perfil na aplicação.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Denúncia',
        warning: 'Aviso',
        close: 'Fechar',
        open: 'Abrir',
        error_general: 'Ocorreu um erro inesperado',
        error_retrieving_profile: 'Ocorreu um erro ao obter o seu perfil',
        something_wrong_saving: 'Ocorreu um erro ao submeter o formulário',
        something_wrong_task: 'Ocorreu um erro ao enviar a tarefa Preencha os comentários ou tente novamente mais tarde',
        something_wrong_logout: 'Ocorreu um erro ao terminar sessão.',
        offline: 'Está atualmente a trabalhar offline. Como resultado, esta função não se encontra disponível de momento',
        account_removed_failed: 'Ocorreu um erro ao eliminar a sua conta. Tente novamente mais tarde.',
        inactive_form: 'Este formulário já não é válido. Preencha novamente o formulário.',
        no_access: 'Não tem acesso ao WAVE. Contacte o seu administrador',
        error_message: 'Mensagem de erro:',
        login_failed: 'Falha no início de sessão',
        error_logged_out: 'Infelizmente, ocorreu um erro ao obter os dados. A sua sessão está a ser terminada',
        retrieving_options: 'Obter opções/contactos',
        aggregating: 'Agregar dados',
        update_failed: 'Falha na edição',
        retrieval_failed: 'Falha na obtenção',
        error_forms_no_network: 'Não é possível editar formulários (sem ligação de rede). Tentar editar formulários novamente?',
        error_forms_slow_network: 'Não é possível editar formulários (ligação de rede lenta). Tentar editar formulários novamente?',
        error_forms_retrieval_no_network: 'Não é possível obter formulários (sem ligação de rede). Verifique a ligação de rede e tente novamente.',
        error_forms_retrieval_slow_network: 'Não é possível obter formulários (ligação de rede lenta). Verifique a ligação de rede e tente novamente.',
        error_popup_blocked: 'Permitir pop-ups para fazer login',
        image_file_needed: 'O arquivo não é uma imagem válida',
        route_not_found: 'Falha no redirecionamento',
        route_not_found_explanation: 'Falha ao redirecionar para a página ou formulário. A causa pode ser que está inacessível para você, a página ou formulário não existe mais ou o formulário não está disponível em seu idioma.',
    },
    FORGOT_PASSWORD: {
        title: 'Esqueci-me da palavra-passe',
        subtitle: 'Se o endereço de e-mail for conhecido, irá receber um e-mail com instruções para redefinir a palavra-passe.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'Introduzir endereço de e-mail',
        email: 'E-mail',
        next: 'Próximo',
    },
    NEWS: {
        title: 'Notícias',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    SEARCH_PLACEHOLDER: 'Procurar',
    PASSWORD_CHANGE: {
        title: 'Alterar palavra-passe',
        current_password: 'Palavra-passe atual',
        password_explanation: 'A palavra-passe tem de ter pelo menos 8 carateres e tem de conter uma letra maiúscula, um número e um carater especial',
        new_password: 'Nova palavra-passe',
        repeat_new_password: 'Repetir nova palavra-passe',
        passwords_dont_match: 'As palavras-passe não coincidem',
        password_changed: 'A sua palavra-passe foi alterada. Tem de iniciar sessão novamente',
        current_password_incorrect: 'A palavra-passe introduzida não corresponde à palavra-passe atual',
        confirm: 'Confirmar',
    },
    QUESTIONNAIRE: {
        part: 'Componente',
        map_offline: 'Está a trabalhar offline. O cartão só está disponível no modo online.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Formulários enviados',
        no_forms: 'Ainda não foram enviadas denúncias.',
        submitted_at: 'Submetido em',
        processed_at: 'Processado em',
        processed_submitted_at: 'Processado, submetido em',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Componente',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Obrigado',
        subtitle: 'O formulário está a ser processado. Será enviada para si uma cópia do formulário por e-mail. O formulário preenchido está também disponível no Histórico desta aplicação.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Criado em:',
        completed_on: 'Preenchido em:',
        place_remark: 'Introduza aqui o(s) seu(s) comentário(s)',
        remark: 'Comentário(s):',
        task_denied: 'Não processado',
        task_completed: 'Processado',
        are_you_sure: 'Tem a certeza de que pretende configurar esta tarefa como "{{statusLabel}}"?',
        page_leave: 'Sair da página',
        page_leave_explanation: 'Preencheu o campo “comentários” da tarefa. Se sair da página, esta alteração será perdida. Tem a certeza de que pretende sair da página?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Tarefas em progresso',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Formulários planeados',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Tarefas concluídas',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Preenchido em:',
        deadline_passed: 'Prazo ultrapassado:',
        deadline_at: 'Prazo ultrapassado em:',
        created_at: 'Criado em:',
    },
    CARD_GAME: {
        question: {
            label: 'Pergunta',
        },
        toolbar: {
            button_label: 'Novo cartão',
            close: 'Fechar',
        },
    },
    ERROR: {
        form: {
            warning: 'Aviso',
            not_found: 'Ainda não há formulários disponíveis em seu idioma.',
            directIncidentOnly: 'O formulário “Denunciar incidente ou MOHS” está indisponível de momento. Contacte o administrador de sistema.',
            directComplimentOnly: 'Este formulário não está disponível no seu idioma.',
            questionnaireIdToSelect: 'Esta tarefa não está atualmente disponível para a unidade organizativa selecionada. Selecione outra unidade organizativa ou contacte o administrador de sistema.',
        },
        no_access: {
            title: 'Sem direitos',
            explanation: 'Você não tem acesso a esses formulários. Volte para a tela inicial.',
        },
    },
    REGISTRATION: {
        title: 'Criar nova conta',
        email: 'E-mail',
        first_name: 'Nome próprio',
        sur_name: 'Apelido',
        mobile_phone: 'Número de telemóvel',
        password: 'Palavra-passe',
        password_explanation: 'A palavra-passe tem de ter pelo menos 8 carateres e tem de conter uma letra maiúscula, um número e um carater especial',
        repeat_password: 'Repetir palavra-passe',
        passwords_dont_match: 'As palavras-passe não coincidem',
        approve: 'Concordo com',
        privacy: 'Política de privacidade',
        create_account: 'Criar conta',
        account_exists: 'Já existe uma conta associada a este endereço de e-mail',
        error_something_wrong: 'Ocorreu um erro com o registo.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Confirme o seu endereço de e-mail',
        subtitle: 'Obrigado por criar uma conta. Dentro de momentos, irá receber um e-mail a pedir-lhe para confirmar o seu endereço de e-mail',
    },
    REGISTRATION_SMS: {
        title: 'Verificação de número de telefone',
        subtitle: 'Introduza aqui o código de verificação que recebeu por mensagem de texto. Este código será enviado para o número de telefone indicado.',
        verification_code: 'Código de verificação',
        verify: 'Verificar código de verificação',
        incorrect_code: 'Código de verificação inválido',
        error_something_wrong: 'Ocorreu um erro com a verificação.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Formulários por preencher',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Formulários planeados',
        available_forms: 'Formulários disponíveis',
        workplace_forms: 'Inspeção no local de trabalho',
        retrieve_forms: 'Obter formulários',
        retrieve_form: 'Obter formulário',
        choose_action: 'Selecione a sua ação',
        open: 'Abrir',
        upload: 'Colocar on-line',
        download_and_open: 'Descarregue e abra o formulário',
        remove: 'Eliminar',
        something_went_wrong: 'Ocorreu um erro ao obter os formulários',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Selecione o seu projeto',
        choose_business_unit: 'Selecione a sua unidade comercial',
        no_organisations: 'Não existem organizações disponíveis.',
        search_on: 'Procurar por',
        choose_your: 'Select your',
        organisation: 'empresa',
        project: 'projeto',
        projects: 'Projetos',
        business_unit: 'unidade comercial',
        business_units: 'Unidades comercial',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Deseja enviar o formulário?',
        description: 'Ao enviá-lo você não poderá mais alterar nada neste formulário enviado.',
        account_blocked: 'Este formulário só pode ser enviado quando a sua conta for aprovada',
        offline: 'Está a trabalhar offline, o que significa que não pode enviar o formulário. Enviar o formulário assim que estiver novamente online',
        something_went_wrong: 'Ocorreu um erro ao submeter o formulário',
        confirm: 'Tak Wyślij',
        cancel: 'Não, de volta',
    },
    SCAN_QR: {
        incorrect_result: 'Código QR incorreto. Digitalize um código QR WAVE correto.',
        no_result: 'Nenhum código QR digitalizado.',
        no_permission: 'O aplicativo não pode acessar a câmera. Dê permissão ao aplicativo antes de digitalizar um código QR.',
        try_again: 'Digitalizar novamente',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Adicionar',
            place_remark: 'Introduza aqui o(s) seu(s) comentário(s)',
            max_attachments_reached: 'Atingiu o número máximo permitido de anexos.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Diversas opções possíveis',
            no_choices_selected: 'Não foi/foram selecionada(s) nenhuma(s) opção(ões).',
        },
        checkbox_searchable: {
            selected_choices: 'Opção(ões) selecionada(s)',
            no_choices_found: 'Não foi/foram encontrada(s) nenhuma(s) opção(ões).',
            visible_choices: '{{maxListChoices}} das opções {{filteredChoices}} estão visíveis.',
            limit_choices: 'Realize uma pesquisa para reduzir a lista de opções.',
        },
        lat_long: {
            current_location: 'Utilizar localização atual',
            or: 'Ou',
            city: 'Localidade/cidade',
            address: 'Endereço',
            invalid_address: 'Este endereço não é válido.',
        },
        manual_choice: {
            new_choice: 'Introduzir nova opção',
            choice_exists: 'Esta opção já existe.',
        },
        photo: {
            subtitle: 'Adicione fotos clicando no sinal de mais',
            gallery: 'Galeria de fotografias',
            drop_explanation: 'Clique, arraste ou cole as fotos aqui para adicioná-las',
            max_reached: 'Atingiu o número máximo permitido de fotografias em anexo.',
            take_a_photo: 'Tire uma foto',
            upload_from_gallery: 'Carregar da galeria',
        },
        questionnaire_footer: {
            chapters: 'Capítulos',
            previous: 'Anterior',
        },
        questionnaire_overlay: {
            help_text: 'Após responder à pergunta, clique em “Seguinte” para avançar para a próxima pergunta.',
        },
        radio: {
            choose: 'Fazer uma seleção',
            no_choice_selected: 'Não foi selecionada nenhuma opção.',
        },
        radio_searchable: {
            selected_choice: 'Opção selecionada',
            no_choice_found: 'Não foram encontradas nenhumas opções.',
            visible_choices: '{{maxListChoices}} das opções {{filteredChoices}} estão visíveis.',
            limit_choices: 'Realize uma pesquisa para reduzir a lista de opções.',
        },
        searchable_choices: {
            search: 'Procurar',
        },
        select_concept: {
            last_change: 'Última alteração:',
        },
        select_item: {
            submit_before: 'Submeter antes de',
        },
    },
};
